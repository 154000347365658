










import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class StepperConsentDownload extends Vue {
  //
  //
  // PROPS
  @Prop({ default: false }) readonly template!: boolean;
  @Prop() readonly itemId!: number;
  @Prop() readonly itemName!: string;
  //
  //
  // DATA
  private downloading = false;
  //
  //
  // METHODS
  download() {
    this.downloading = true;
    setTimeout(async () => {
      this.$emit(
        "continue",
        await this.$store.dispatch("ItemModule/downloadConsent", this.itemId)
      );
      this.downloading = false;
    }, 0);
  }
}
